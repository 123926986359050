// Import CSS
import "intl-tel-input/build/css/intlTelInput.min.css";

export const useIntlTelInput = async () => {
  try {
    // Import module
    const moduleRequest = await import("intl-tel-input");
    return moduleRequest.default;
  } catch (err: any) {
    throw new Error(
      "Failed to import module 'intl-tel-input'. Error: " + err?.message
    );
  }
};

export default useIntlTelInput;
